<template>
    <div>
        <router-view :tenant="tenant" :event="event" :race="race"></router-view>
    </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import eventManagerService from "@/services/eventManagerService";
import siteData from '@/data/site.json'
import EventUtil from "@/util/eventUtil";
import Header from './_Header.vue'

export default {
  name: "EventLayoutBase",
  components: {
    Header,
  },
  props: {
    tenant: Object,
    event: Object,
  },
  data() {
    return {
    };
  },
  async mounted() {
  },
  methods: {
  },
  computed: {
    race() {
      return this.event && this.event.races ? this.event.races.find(x => x.id == this.$route.params.raceId) : null;
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

